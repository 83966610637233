import addToMailchimp from 'gatsby-plugin-mailchimp'

import React, { useState } from 'react'
import typographySizes from 'src/assets/styles/typographySizes.js'
import colors from 'src/assets/styles/colors.js'
import styled, { keyframes } from 'styled-components'
import mediaQuery from 'src/assets/styles/mediaQuery'

import MainWrapper from 'src/components/global/MainWrapper.js'

import Hex from 'src/assets/svg/newsletter-hex.svg'

const FormWrapper = styled.div`
  background-color: ${colors.brightGreen};
  border-radius: 23rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40rem;
  padding-bottom: 15rem;
  margin-bottom: 80rem;
  padding-left: 50rem;
  padding-right: 50rem;
  background-image: url(${Hex});
  background-position: 100% center;
  background-repeat: no-repeat;
  .classFieldset {
    border: none !important;
    margin-top: 30rem !important;

    max-width: 630rem !important;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding-left: 10rem;
    padding-right: 10rem;
    margin-left: auto;
    margin-right: auto;
    background-position: 60vw center;
    border-radius: 9rem;
  }
`

const Title = styled.h2`
  font-size: ${typographySizes.m}rem;
  color: ${colors.white};
  max-width: 450rem;
  text-align: center;
  line-height: 1.1;
  margin-bottom: 10rem;
`

const Description = styled.p`
  font-size: ${typographySizes.s}rem;
  color: ${colors.white};
  font-family: 'Poppins', sans-serif;
  margin-bottom: 50rem;
  max-width: 540rem;
  text-align: center;
  span {
    font-size: ${typographySizes.s}rem;
    color: ${colors.white};
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
  }
`

const InputButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
`

const StyledImput = styled.input`
  font-size: ${typographySizes.s}rem;
  border-radius: 10rem;
  border: solid 1rem #fff;
  background-color: #bfce46;
  height: 50rem;
  padding-left: 15rem;
  width: calc(100% - 220rem);
  max-width: 420rem;
  font-family: 'Poppins', sans-serif;
  &::-webkit-input-placeholder {
    color: ${colors.white};
    opacity: 0.6;
    font-size: ${typographySizes.s}rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 17rem);
    max-width: initial;
  }
`

const success = keyframes`
   0%, 100% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-100%);
  }
  77% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(-200%);
  }
`

const Button = styled.button`
  display: inline-block;
  height: 50rem;
  padding: 0 30rem;

  border-radius: 9rem;
  line-height: 50rem;
  text-align: center;
  font-size: ${typographySizes.s}rem;
  cursor: pointer;
  color: ${colors.darkGreen};
  background-color: ${colors.white};
  border: solid 1rem ${colors.white};
  margin-left: 30rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  overflow: hidden;
  :hover {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    color: ${colors.white};
    background-color: ${colors.darkGreen};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 10rem;
    margin-left: 0rem;
  }

  span {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    display: block;
    position: relative;
    top: -2px;
  }
  &.success {
    span {
      animation: ${success} 3s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`

const Message = styled.div`
  margin-top: 10rem;
  font-size: 10rem;
  color: ${colors.darkGreen};
`
const StyledFieldset = styled.fieldset`
  margin-top: 30rem;

  max-width: 630rem;
`

const StyledCheckmark = styled.span`
  display: block;

  position: relative;
  top: -1px;
  width: 12px;
  height: 12px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  margin-right: 10rem;
  cursor: pointer;
  border: solid 1rem #fff;
  background-color: #bfce46;
  border-radius: 100%;
  flex-shrink: 0;
`

const LabelWrapper = styled.label`
  display: flex;
  .gdpr {
    display: none;
  }
  .gdpr:checked + ${StyledCheckmark} {
    background-color: ${colors.darkGreen};
  }
`

const Checkmark = styled.input.attrs({ type: 'checkbox' })`
  /* display: none;
  &:checked + ${StyledCheckmark} {
    background-color: ${colors.darkGreen};
  } */
`

const GdprText = styled.span`
  font-size: 10rem;
  color: ${colors.white};
  font-family: 'Poppins', sans-serif;
  max-width: 640rem; ;
`
const MailChimpForm = () => {
  const [email, setEmail] = useState('')
  const [gdpr, setGdpr] = useState()
  const [result, setResult] = useState('')
  const [msg, setMsg] = useState(null)
  const [isChecked, setisChecked] = useState(false)
  const [success, setSuccess] = useState(false)

  const _handleSubmit = async e => {
    e.preventDefault()
    if (isChecked) {
      const result = await addToMailchimp(email, { 'gdpr[357182]': 'Y' })
      setResult(result)

      setMsg(result.msg)
      if (result.result === 'success') {
        setSuccess(true)
        setInterval(function () {
          setSuccess(false)
        }, 3100)
      }
    } else {
      setMsg('Wyrażenie zgody jest wymagane')
    }
  }
  const handleChange = event => {
    setEmail(event.target.value)
  }
  const handleChangeGdpr = event => {
    const premision = {
      marketing_permissions: [
        { marketing_permission_id: 'gdpr[357182]', enabled: true }, //
      ],
    }
    setGdpr(premision)
  }

  const selectShortlistedApplicant = e => {
    const checked = e.target.checked
    if (!isChecked) {
      setisChecked(true)
    } else {
      setisChecked(false)
    }
  }

  return (
    <MainWrapper>
      <FormWrapper>
        <Title
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          Dołącz do zielonego świata Kimidori
        </Title>
        <Description
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          Zapisz się do newslettera i bądź na bieżąco z obecnymi promocjami i
          ofertami. <span> Nie przegap żadnej okazji! </span>
        </Description>
        <form onSubmit={_handleSubmit}>
          <InputButtonWrapper
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="500"
          >
            <StyledImput
              id="outlined-email-input"
              placeholder="podaj Email"
              type="email"
              name="email"
              autoComplete="email"
              variant="outlined"
              onChange={handleChange}
            />
            <Button
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              className={success ? 'success' : ''}
            >
              <span>zapisz się</span>
              <span>dziękujemy</span>
              <span>zapisz się</span>
            </Button>
          </InputButtonWrapper>

          <StyledFieldset
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="500"
            name="interestgroup_field"
            className="classFieldset mc_fieldset gdprRequired mc-field-group"
          >
            <LabelWrapper
              for="gdpr_357182"
              onClick={e => {
                selectShortlistedApplicant(e)
              }}
            >
              <input
                type="checkbox"
                id="gdpr_357182 acceptTerms"
                name="gdpr[357182]"
                value="Y"
                className="av-checkbox gdpr"
                type="checkbox"
                onChange={handleChangeGdpr}
                checked={isChecked}
              />
              <StyledCheckmark />
              <GdprText>
                Wyrażam zgodę na przetwarzanie moich danych osobowych w celu
                prowadzenia przez Terra Green Sp. z o.o. z siedzibą w Warszawie
                marketingu bezpośredniego.
                {result.msg ? <Message> {msg}</Message> : <Message> </Message>}
                {!isChecked ? <Message> {msg}</Message> : <Message> </Message>}
              </GdprText>
            </LabelWrapper>
          </StyledFieldset>
        </form>
      </FormWrapper>
    </MainWrapper>
  )
}

export default MailChimpForm
