import React, { useRef, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import typographySizes from 'src/assets/styles/typographySizes.js'
import colors from 'src/assets/styles/colors.js'
import mediaQuery from 'src/assets/styles/mediaQuery.js'

import ButtonOrder from 'src/components/global/ButtonOrder.js'

import hex from 'src/assets/svg/orderOnline-arrow.svg'
import MainWrapper from 'src/components/global/MainWrapper.js'
import arrow from '/src/assets/svg/testimonial_arrow.svg'
import map from 'src/assets/svg/kimidori_mapa_2.svg'

const Wrapper = styled.section`
  margin-bottom: 80rem;
`

const MapWrapper = styled.div`
  width: calc(100% - 60rem);
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  background-color: ${colors.bright};
  padding: 30rem;
  border-radius: 40rem;
  @media (max-width: ${mediaQuery.laptop}) {
    flex-direction: column;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    border-radius: 9rem;
  }
`

const LeftCol = styled.div`
  width: calc(50% - 15rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin-bottom: 50rem;
  }
`

const Title = styled.h2`
  font-size: ${typographySizes.l}rem;
  color: ${colors.darkGreen};

  line-height: 1;
  text-transform: uppercase;
  span {
    vertical-align: super;
    line-height: 0;
    position: relative;
    top: -5rem;
    color: ${colors.darkGreen};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}rem;
  }
`

const OutsideWrapper = styled.div`
  border-top: solid 1rem black;
  border-bottom: solid 1rem black;
  margin-top: 50rem;
  padding: 30rem 0;
`

const SecTitle = styled.h3`
  font-size: ${typographySizes.sm}rem;
  color: ${colors.darkGreen};
  margin-bottom: 20rem;
  text-transform: uppercase;
  line-height: 1;
`

const DeliDescription = styled.p`
  font-size: ${typographySizes.s}rem;
  line-height: 1.2;
  font-family: 'Poppins', sans-serif;
  margin-right: 30rem;
  max-width: 480rem;
  a {
    color: ${colors.darkGreen};
    font-size: inherit;
    text-decoration: none;
  }
`
const AditionalInfoWrapper = styled.div`
  display: flex;
  margin-top: 30rem;
  align-items: center;
  p {
    font-size: 10rem;
    margin-right: 30rem;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    img {
      transform: rotate(90deg);
    }
  }
`

const RighCol = styled.div`
  width: calc(50% - 15rem);
  height: 45vw;

  overflow: hidden;
  border-radius: 20rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 90%;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    height: 90vw;
  }
`

const OrderOnlineSection = props => {
  return (
    <Wrapper id="dostawa">
      <MainWrapper>
        <MapWrapper>
          <LeftCol>
            <div>
              <Title>
                Dostawa gratis <br /> na terenie całej <br /> Warszawy!
                <span>*</span>
              </Title>
              <ButtonOrder>Zamów </ButtonOrder>
            </div>
            <div>
              <OutsideWrapper>
                <SecTitle>Dowozimy też poza Warszawą!</SecTitle>
                <DeliDescription>
                  zadzwoń <a href="tel:22 400 66 33"> 22 400 66 33 </a> aby
                  poznać indywidualne warunki dostawy na terenie województwa
                  mazowieckiego
                </DeliDescription>
              </OutsideWrapper>
              <AditionalInfoWrapper>
                <p>*Sprawdź minimalne kwoty na mapie dowozu</p>{' '}
                <img src={arrow} />
              </AditionalInfoWrapper>
            </div>
          </LeftCol>
          <RighCol>
            {' '}
            <img src={map} />
            {/* <StaticImage
              src="../../assets/images/opinie/opinia8.jpg"
              placeholder="none"
              objectFit="cover"
              quality="100"
            /> */}
          </RighCol>
        </MapWrapper>
      </MainWrapper>
    </Wrapper>
  )
}

export default OrderOnlineSection
