import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'

import typographySizes from 'src/assets/styles/typographySizes.js'
import colors from 'src/assets/styles/colors.js'
import mediaQuery from 'src/assets/styles/mediaQuery.js'
import distances from 'src/assets/styles/distances.js'

import img1 from 'src/assets/images/row-1.png'
import img2 from 'src/assets/images/row-2.png'
import img3 from 'src/assets/images/row-3.png'
import introImg from 'src/assets/images/intro-img.png'
import smalHex from 'src/assets/svg/intro-small-hex.svg'
import biHex from 'src/assets/svg/intro-big-hex.svg'

import MainWrapper from 'src/components/global/MainWrapper.js'
import Button from 'src/components/global/Button.js'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`

const StyledImgDiv = styled.div`
  width: 33.33%;
  padding-bottom: 33.33%;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.img});
  &:nth-of-type(1) {
    transition-delay: 0.3s;
  }
  &:nth-of-type(2) {
    transition-delay: 0s;
  }
  &:nth-of-type(3) {
    transition-delay: 0.3s;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 50%;
    padding-bottom: 50%;
    transition-delay: 0.4s;
    :nth-of-type(3) {
      display: none;
    }
  }
`

const ImgRowSection = props => (
  <Wrapper>
    <StyledImgDiv
      data-sal="slide-up"
      data-sal-easing="ease"
      data-sal-duration="500"
      img={img1}
    />
    <StyledImgDiv
      data-sal="slide-up"
      data-sal-easing="ease"
      data-sal-duration="500"
      img={img2}
    />
    <StyledImgDiv
      data-sal="slide-up"
      data-sal-easing="ease"
      data-sal-duration="500"
      img={img3}
    />
  </Wrapper>
)

export default ImgRowSection
