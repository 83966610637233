import React, { useRef, useEffect } from 'react'
import Parallax from 'parallax-js' // Now published on NPM
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import mediaQuery from 'src/assets/styles/mediaQuery.js'

import leafLeft from 'src/assets/images/leaf-top-lef.png'
import leafTopRight from 'src/assets/images/leaf-top-right.png'
import leafBottomRight from 'src/assets/images/leaf-top-right.png'

const BgWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
`

const HeaderTopRightImgWrapper = styled.div`
  width: 100vw;
  height: 100%;
  position: absolute;
  top: -10vw !important;
  left: 10vw !important;
  img {
    width: 100vw;
  }
`

const HeaderBottomRightImgWrapper = styled.div.attrs(
  ({ $movedX, $movedY }) => ({
    style: {
      transform: `translate(${$movedX}px, ${$movedY}px)`,
    },
  }),
)`
  width: 100vw;
  height: 100%;
  position: absolute;
  top: -10vw !important;
  left: -10vw !important;
`

const HeaderTopLeftImgWrapper = styled.div`
  width: 100vw;
  height: 100%;
  position: absolute;
  top: -10vw !important;
  left: -10vw !important;
`

const BgLeaf = () => {
  const sceneEl = useRef(null)
  const sceneEl2 = useRef(null)

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
      hoverOnly: false,
    })

    const parallaxInstance2 = new Parallax(sceneEl2.current, {
      relativeInput: true,
      hoverOnly: false,
    })

    parallaxInstance.enable()
    parallaxInstance2.enable()

    return () => parallaxInstance.disable()
  }, [])

  return (
    <>
      <BgWrapper
        ref={sceneEl}
        data-invert-x="false"
        data-friction-x="0.05"
        data-friction-y="0.05"
      >
        <HeaderTopLeftImgWrapper className="layer" data-depth=".50">
          <img
            src={leafLeft}
            placeholder="none"
            layout="fullWidth"
            quality="100"
          />
        </HeaderTopLeftImgWrapper>
      </BgWrapper>
      <BgWrapper
        ref={sceneEl2}
        data-invert-x="true"
        data-friction-x="0.05"
        data-friction-y="0.05"
        data-sal="slide-down"
        data-sal-delay="1000"
        data-sal-easing="ease"
        data-sal-duration="500"
      >
        <HeaderTopRightImgWrapper className="layer" data-depth=".75">
          <img
            src={leafTopRight}
            placeholder="none"
            layout="fullWidth"
            quality="100"
          />
        </HeaderTopRightImgWrapper>
      </BgWrapper>
    </>
  )
}

export default BgLeaf

// import React, { Component, useRef, useEffect } from "react";
// import Parallax from "parallax-js"; // Now published on NPM
// import styled from "styled-components";

// const Wrap = styled.div`
//   height: 500px;
//   width: 500px;
//   background-color: blue;
// `;

// const TestDiv = styled.div`
//   height: 100px;
//   width: 100px;
//   background: red;
// `;

// const Test = () => {
//   const sceneEl = useRef(null);

//   useEffect(() => {
//     const parallaxInstance = new Parallax(sceneEl.current, {
//       relativeInput: true,
//       hoverOnly: false,
//     });

//     parallaxInstance.enable();

//     return () => parallaxInstance.disable();
//   }, []);

//   return (
//     <Wrap ref={sceneEl}>
//       <TestDiv className="layer" data-depth="2.00"></TestDiv>

//       <TestDiv className="layer" data-depth="1.00"></TestDiv>
//     </Wrap>
//   );
// };

// export default Test;
