import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import typographySizes from 'src/assets/styles/typographySizes.js'
import colors from 'src/assets/styles/colors.js'
import mediaQuery from 'src/assets/styles/mediaQuery.js'
import distances from 'src/assets/styles/distances.js'
import Parallax from 'parallax-js' // Now published on NPM

import MainWrapper from 'src/components/global/MainWrapper.js'
import ButtonOrder from 'src/components/global/ButtonOrder.js'

const Wrapper = styled.section`
  background-color: ${colors.darkGreen};
  padding: 120rem ${distances.s}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 60rem ${distances.xs}rem;
  }
`

const StyledMainWrapper = styled(MainWrapper)`
  overflow: inherit;
  height: 55vw;
  &.mobile {
    display: none;
  }

  @media (max-width: ${mediaQuery.tablet}) {
    display: none;

    &.mobile {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      overflow: inherit;
      height: auto;
    }
  }
`

// Position Component

const PositionWrapper = styled.div`
  width: calc(100% - ${distances.s * 2}rem);
  position: absolute;
  margin-top: 50rem;
  & > div {
    width: 25%;
  }

  &:nth-of-type(2) > div {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
  &:nth-of-type(3) > div {
    right: 0;
    position: absolute;
  }
  &:nth-of-type(4) > div {
    top: 20vw;
    left: 18vw;
    position: absolute;
  }
  &:nth-of-type(5) > div {
    top: 20vw;
    right: 18vw;
    position: absolute;
  }

  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(50% - 15rem);
    position: relative;
    & > div {
      width: 100%;
      position: initial;
    }
  }
`

const Title = styled.h2`
  font-size: ${typographySizes.m}rem;
  text-transform: uppercase;
  text-align: center;
  color: ${colors.white};
  line-height: 1.1;
  max-width: 850rem;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
  }
`

const MenuSection = props => {
  const sceneEl1 = useRef(null)

  useEffect(() => {
    const parallaxInstance1 = new Parallax(sceneEl1.current, {
      relativeInput: true,
      hoverOnly: false,
    })
  }, [])
  return (
    <Wrapper id="menu">
      <Title>
        Odkryj roślinny lunch, zupy, dania główne, zestawy i inne w pełni
        wegańskie pozycje w menu. Dziękujemy, że jesteś GREEN!
      </Title>
      <ButtonOrder tertiary>Poznaj menu i ZAMÓW!</ButtonOrder>
      <StyledMainWrapper className="mobile">
        <PositionWrapper>
          <StaticImage
            src="../../assets/images/zestawy-menu.png"
            placeholder="none"
            layout="fullWidth"
            quality="100"
          />
        </PositionWrapper>
        <PositionWrapper>
          <StaticImage
            src="../../assets/images/maki-menu.png"
            placeholder="none"
            layout="fullWidth"
            quality="100"
          />
        </PositionWrapper>
      </StyledMainWrapper>
      <StyledMainWrapper ref={sceneEl1}>
        <PositionWrapper data-depth=".30">
          <div>
            <StaticImage
              src="../../assets/images/maki-menu.png"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </div>
        </PositionWrapper>
        <PositionWrapper data-depth=".70">
          <div>
            <StaticImage
              src="../../assets/images/nigiri-menu.png"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </div>
        </PositionWrapper>
        <PositionWrapper data-depth="0.50">
          <div>
            <StaticImage
              src="../../assets/images/przystawki-menu.png"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </div>
        </PositionWrapper>
        <PositionWrapper data-depth="0.50">
          <div>
            <StaticImage
              src="../../assets/images/zestawy-menu.png"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </div>
        </PositionWrapper>
        <PositionWrapper data-depth="0.40">
          <div>
            <StaticImage
              src="../../assets/images/new-menu.png"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </div>
        </PositionWrapper>
      </StyledMainWrapper>
    </Wrapper>
  )
}

export default MenuSection
